<template>
    <div class="filter-items">
        <!-- (SLOT is necesary for ViewConfigForm) -->
        <slot></slot>

        <!-- TYPE LOCATION -->
        <div class="filter">
            <div v-if="temporaryAvailable == 1" class="filter">
                <label>{{ $t('user.filters.template_type') }}</label>
                <!-- TEMPLATE TYPE -->
                <v-select
                    :value="currentFilters.tplType"
                    hide-details
                    class="select"
                    @input="
                        (value) => {
                            $emit('inputChange', { inputName: 'tplType', value: value })
                        }
                    "
                    :class="{
                        filtered: currentFilters.tplType ? currentFilters.tplType && Object.values(currentFilters.tplType).length > 0 : '',
                        changed:
                            JSON.stringify(currentFilters.tplType) != JSON.stringify(activeFilters.tplType) &&
                            currentFilters.tplType &&
                            Object.values(currentFilters.tplType).length > 0
                    }"
                    v-if="Object.values(optionsTplType)"
                    :items="Object.values(optionsTplType)"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('user.filters.filter_by')"
                ></v-select>
            </div>

            <!-- TEMPLATES -->
            <div class="filter">
                <label>{{ $t('supervise.checklists.table_headers.template') }}</label>
                <v-autocomplete
                    :value="currentFilters.template"
                    class="select"
                    @input="
                        (templateInputValue) => {
                            $emit('inputChange', { inputName: 'template', value: templateInputValue })
                        }
                    "
                    :class="{
                        filtered: currentFilters.template ? currentFilters.template && Object.values(currentFilters.template).length > 0 : '',
                        changed:
                            JSON.stringify(currentFilters.template) != JSON.stringify(activeFilters.template) &&
                            currentFilters.template &&
                            Object.values(currentFilters.template).length > 0
                    }"
                    :items="Object.values(optionsTemplates)"
                    hide-details
                    item-text="name"
                    item-value="id"
                    multiple
                    attach
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    :filter="filterAutocomplete"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>

                    <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                            <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-row no-gutters class="options" v-html="item.name"></v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>

                <Tags :tags="currentFilters.template" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <div class="filter">
                <label>{{ $t('user.filters.search_by') }}</label>
                <!-- SITES (UBICACIONES) -->
                <v-select
                    :value="currentFilters.sites"
                    :class="{
                        filtered: currentFilters.sites,
                        changed:
                            currentFilters.sites !== undefined &&
                            activeFilters.sites !== undefined &&
                            JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                    }"
                    hide-details
                    class="select"
                    @input="
                        (value) => {
                            $emit('inputChange', { inputName: 'sites', value: value })
                        }
                    "
                    v-if="Object.values(optionsSites)"
                    :items="Object.values(optionsSites)"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                ></v-select>
            </div>

            <!-- LOCATIONS -->
            <div class="sites" v-if="currentFilters.sites == 'locations'">
                <label class="description">{{ $t('user.filters.available_sites') }}</label>
                <v-autocomplete
                    :value="currentFilters.locations"
                    :class="{
                        filtered:
                            currentFilters.locations && typeof currentFilters.locations !== 'undefined' && Object.values(currentFilters.locations).length > 0,
                        changed:
                            JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations) &&
                            currentFilters.locations &&
                            Object.values(currentFilters.locations).length > 0
                    }"
                    hide-details
                    class="select"
                    @input="
                        (locationsInputValue) => {
                            $emit('inputChange', { inputName: 'locations', value: locationsInputValue })
                        }
                    "
                    v-if="Object.values(optionsLocations)"
                    :items="Object.values(optionsLocations)"
                    item-text="name"
                    item-value="id"
                    multiple
                    attach
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="currentFilters.locations ? '' : $t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags
                    v-if="currentFilters.locations"
                    :tags="currentFilters.locations"
                    :backImage="'location_inactive_s30.svg'"
                    :nameTag="'name'"
                    value="id"
                    :removable="true"
                ></Tags>
            </div>

            <!-- GROUPS -->
            <div class="custom" v-else-if="currentFilters.sites == 'groups'">
                <label class="description">{{ $t('user.filters.available_territories') }}</label>
                <v-autocomplete
                    :value="currentFilters.groups"
                    :class="{
                        filtered:
                            currentFilters.groups &&
                            typeof currentFilters.groups !== 'undefined' &&
                            currentFilters.groups &&
                            Object.values(currentFilters.groups).length > 0,
                        changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                    }"
                    hide-details
                    class="select"
                    @input="
                        (inputValue) => {
                            $emit('inputChange', { inputName: 'groups', value: inputValue })
                        }
                    "
                    v-if="Object.values(optionsGroups)"
                    :items="Object.values(optionsGroups)"
                    item-text="name"
                    item-value="id"
                    multiple
                    attach
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="currentFilters.groups ? '' : $t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags v-if="currentFilters.groups" :tags="currentFilters.groups" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <!-- TYPE TIMEFRAME -->
        <div class="filter">
            <label>{{ $t('supervise.checklists.table_headers.timeframe') }}</label>
            <v-autocomplete
                :value="currentFilters.timeframe"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'timeframe', value: value })
                    }
                "
                hide-details
                class="select"
                :class="{
                    filtered: currentFilters.timeframe && Object.values(currentFilters.timeframe).length > 0,
                    changed:
                        JSON.stringify(currentFilters.timeframe) != JSON.stringify(activeFilters.timeframe) &&
                        currentFilters.timeframe &&
                        Object.values(currentFilters.timeframe).length > 0
                }"
                :items="Object.values(optionsTimeframes)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :filter="filterAutocomplete"
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>

            <Tags :tags="currentFilters.timeframe" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- TYPE CATEGORY -->
        <div class="filter">
            <label>{{ $t('supervise.checklists.table_headers.category') }}</label>
            <v-autocomplete
                :value="currentFilters.category"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'category', value: value })
                    }
                "
                :items="optionsCategories ? Object.values(optionsCategories) : []"
                class="select"
                :class="{
                    filtered: currentFilters.category && Object.values(currentFilters.category).length > 0,
                    changed:
                        JSON.stringify(currentFilters.category) != JSON.stringify(activeFilters.category) &&
                        currentFilters.category &&
                        Object.values(currentFilters.category).length > 0
                }"
                hide-details
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :filter="filterAutocomplete"
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>

            <Tags :tags="currentFilters.category" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- TYPE DATE -->
        <div class="filter date">
            <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
            <v-select
                :value="currentFilters.type_date"
                :class="{
                    filtered: currentFilters.type_date,
                    changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
                }"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'type_date', value: value })
                    }
                "
                :items="optionsDate"
                hide-details
                class="select"
                item-text="name"
                item-value="value"
                dense
                :no-data-text="$t('supervise.filter.no_results')"
            ></v-select>

            <!-- CUSTOM DATE -->
            <div class="custom" v-if="currentFilters.type_date == 'custom'">
                <!-- START DATE -->
                <div class="description">{{ $t('assets.filter.from') }}:</div>

                <input
                    :value="currentFilters.start_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'start_date', value: event.target.value })
                        }
                    "
                    :max="this.currentFilters.end_date"
                    type="date"
                    class="date-input start"
                />

                <div class="separator"></div>

                <!-- END DATE -->
                <div class="description">{{ $t('assets.filter.to') }}:</div>

                <input
                    :value="currentFilters.end_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'end_date', value: event.target.value })
                        }
                    "
                    :min="currentFilters.start_date"
                    type="date"
                    class="date-input end"
                />
            </div>
        </div>

        <!-- TYPE SCORE
            Change Title Filter @hom669 -->
        <div class="filter">
            <label>{{ $t('supervise.checklists.table_headers.completed_tasks') }}</label>
            <v-range-slider
                step="5"
                :value="currentFilters.score"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'score', value: value })
                    }
                "
                hide-details
                class="score"
                thumb-size="25"
                thumb-label="always"
            ></v-range-slider>
        </div>

        <!-- TYPE TAGS -->
        <div class="filter">
            <label> {{ $t('template.general.tags.title') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.tags && typeof currentFilters.tags !== 'undefined' && Object.values(currentFilters.tags).length > 0,
                    changed: JSON.stringify(currentFilters.tags) != JSON.stringify(activeFilters.tags)
                }"
                hide-details
                class="select"
                v-model="currentFilters.tags"
                :items="Object.values(optionsTags)"
                item-text="name"
                item-value="id"
                multiple
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags :tags="currentFilters.tags" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    components: { Tags },
    props: {
        currentFilters: { type: Object },
        activeFilters: { type: Object }
    },

    data() {
        return {
            temporaryAvailable: JSON.parse(localStorage.getItem('config')).tplTemporary
        }
    },

    computed: {
        optionsLocations() {
            return this.$store.getters['filterLocations/getFilterLocation']()
        },
        optionsGroups() {
            return this.$store.getters['filterLocations/getFilterGroups']()
        },
        optionsTags() {
            return this.$store.getters['tasks/getFilterTags']()
        },
        optionsTplType() {
            return [
                { value: 'all', name: i18n.t('user.filters.tplTypes.all') },
                { value: 'planned', name: i18n.t('user.filters.tplTypes.scheduled') },
                { value: 'temporary', name: i18n.t('user.filters.tplTypes.temporal') }
            ]
        },
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                {
                    value: 'groups',
                    name: i18n.t('user.filters.territories')
                }
            ]
        },

        optionsTemplates() {
            const templates = Object.values(JSON.parse(JSON.stringify(this.$store.getters['tasks/getFilterTemplate']())))
            // add inactive at final name of the template
            if (templates) {
                for (let i = 0; i < templates.length; i++) {
                    if (templates[i].status == '-2') {
                        templates[i].name =`${templates[i].name} <span class="inactive-card">${this.$t('template.inactive')}</span>`
                    } else if (templates[i].status == '-3') {
                        templates[i].name = `${templates[i].name} <span class="inactive-card">${this.$t('template.ended')}</span>`
                    }
                }
            }

            if (this.currentFilters.tplType == 'temporary') {
                const temporaryTemplates = templates.filter((template) => {
                    return template.temporary !== false
                })
                return temporaryTemplates
            } else if (this.currentFilters.tplType == 'planned') {
                const plannedTemplates = templates.filter((template) => {
                    return template.temporary === false
                })
                return plannedTemplates
            }

            return templates
        },
        optionsTimeframes() {
            return this.$store.getters['tasks/getFilterTimeframe']()
        },

        optionsCategories() {
            return this.$store.getters['tasks/getFilterCategory']()
        },

        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        }
    },

    methods: {
        filterAutocomplete(item, queryText, itemText) {
            const hasValue = (val) => (val != null ? val : '')

            let text = _.deburr(hasValue(itemText).toString().toLowerCase())
            let query = _.deburr(hasValue(queryText).toString().toLowerCase())

            return text.indexOf(query) > -1
        }
    },

    mounted() {}
}
</script>

<style lang="scss" scoped>

    .filter-items {
        .filter {
            .filter {
                .options {
                    align-items: center;
                }
            }
        }
    }

</style>
