<template>
    <div class="view-config-inputs" ref="viewConfigInputs">
        <!-- INPUT NAME -->
        <div class="filter">
            <label>{{ $t('user.user_name') }}</label>

            <input
                type="text"
                class="input"
                :value="viewName"
                @input="
                    (event) => {
                        $emit('inputChange', { inputName: 'viewName', value: event.target.value })
                    }
                "
            />

            <p v-if="inputNameErrorMsg" class="error-message">{{ inputNameErrorMsg }}</p>
        </div>
        <!-- RADIO BUTTONS (choose "Only for me" or "Entire brand") -->
        <div v-if="userRolLevelIsSevenOrMore" class="radio-group">
            <v-radio-group
                :value="viewType"
                @change="
                    (value) => {
                        $emit('inputChange', { inputName: 'viewType', value: value })
                    }
                "
            >
                <!-- "Only for me" option -->
                <div class="radio-option">
                    <v-radio color="#1d7672" :value="3"></v-radio>
                    <label>{{ $t('assets.views.view_only_for_me') }}</label>
                </div>
                <!-- "For the entire brand" option -->
                <div class="radio-option">
                    <v-radio color="#1d7672" :value="2"></v-radio>
                    <label>{{ $t('assets.views.view_for_account') }}</label>
                </div>
            </v-radio-group>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ViewConfigInputs',
    props: {
        viewName: { type: String },
        viewType: { type: Number },
        inputNameErrorMsg: { type: String },
        userRolLevelIsSevenOrMore: { type: Boolean }
    }
}
</script>

<style lang="scss" scoped>
.view-config-inputs {
    padding-top: 1em;

    .error-message {
        color: $color-error-500;
        @include font-size(sm);
    }

    .radio-group {
        padding-top: 1em;
        padding-left: 1em;

        .v-input--selection-controls {
            margin-top: 0px !important;
        }

        .v-radio-group {
            .v-input__slot {
                margin-bottom: 0px !important;
            }
        }

        .radio-option {
            display: flex;
            align-items: center;
            padding-bottom: 0.5em;
        }

        .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
            margin-bottom: 0px !important;
        }

        input[type='radio'] {
            appearance: none;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background-clip: content-box;
            border: 2px solid rgba(255, 252, 229, 1);
            background-color: rgba(255, 252, 229, 1);
        }

        input[type='radio']:checked {
            background-color: #000;
            padding: 4px;
            border: 2px solid #000;
        }
    }
}
</style>
