<template>
    <div v-if="currentFilters" class="filter-items">
        <!-- (SLOT is necesary for ViewConfigForm) -->
        <slot></slot>

        <div class="filter">
            <label>{{ $t('user.filters.search_by') }}</label>
            <!-- SITES (UBICACIONES) -->
            <v-select
                :value="currentFilters.sites"
                :class="{
                    filtered: currentFilters.sites,
                    changed:
                        currentFilters.sites !== undefined &&
                        activeFilters.sites !== undefined &&
                        JSON.stringify(currentFilters.sites) != JSON.stringify(activeFilters.sites)
                }"
                hide-details
                class="select"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'sites', value: value })
                    }
                "
                v-if="Object.values(optionsSites)"
                :items="Object.values(optionsSites)"
                item-text="name"
                item-value="value"
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="$t('assets.filter.filter_by')"
            ></v-select>
        </div>

        <!-- LOCATIONS -->
        <div class="sites" v-if="currentFilters.sites == 'locations'">
            <label class="description">{{ $t('user.filters.available_sites') }}</label>
            <v-autocomplete
                :value="currentFilters.locations"
                :class="{
                    filtered:
                        currentFilters.locations && 
                        typeof currentFilters.locations !== 'undefined' && 
                        Object.values(currentFilters.locations).length > 0,
                    changed:
                        JSON.stringify(currentFilters.locations) != JSON.stringify(activeFilters.locations) &&
                        currentFilters.locations &&
                        Object.values(currentFilters.locations).length > 0
                }"
                hide-details
                class="select"
                @input="
                    (locationsInputValue) => {
                        $emit('inputChange', { inputName: 'locations', value: locationsInputValue })
                    }
                "
                v-if="Object.values(optionsLocations)"
                :items="Object.values(optionsLocations)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentFilters.locations ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags
                v-if="currentFilters.locations"
                :tags="currentFilters.locations"
                :backImage="'location_inactive_s30.svg'"
                :nameTag="'name'"
                value="id"
                :removable="true"
            ></Tags>
        </div>
        <!-- GROUPS -->
        <div class="custom" v-else-if="currentFilters.sites == 'groups'">
            <label class="description">{{ $t('user.filters.available_territories') }}</label>
            <v-autocomplete
                :value="currentFilters.groups"
                :class="{
                    filtered:
                        currentFilters.groups &&
                        typeof currentFilters.groups !== 'undefined' &&
                        currentFilters.groups &&
                        Object.values(currentFilters.groups).length > 0,
                    changed: JSON.stringify(currentFilters.groups) != JSON.stringify(activeFilters.groups)
                }"
                hide-details
                class="select"
                @input="
                    (inputValue) => {
                        $emit('inputChange', { inputName: 'groups', value: inputValue })
                    }
                "
                v-if="Object.values(optionsGroups)"
                :items="Object.values(optionsGroups)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentFilters.groups ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>
            <Tags v-if="currentFilters.groups" :tags="currentFilters.groups" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>

        <!-- EMPLOYEES ("MADE BY")-->
        <div class="filter">
            <label>{{ $t('supervise.records.table_headers.done_by') }}</label>
            <v-autocomplete
                :class="{
                    filtered: currentFilters.employees && Object.values(currentFilters.employees).length > 0,
                    changed: JSON.stringify(currentFilters.employees) != JSON.stringify(activeFilters.employees)
                }"
                :items="fullNameEmployee"
                :value="currentFilters.employees"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'employees', value: value })
                    }
                "
                :placeholder="$t('assets.filter.filter_by')"
                :no-data-text="$t('supervise.filter.no_results')"
                hide-details
                class="select"
                item-text="fullName"
                item-value="id"
                multiple
                attach
                dense
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>

            <Tags :tags="currentFilters.employees" :limit="2" :nameTag="'fullName'" value="id" :removable="true"></Tags>
        </div>

        <!-- DATE -->
        <div class="filter date">
            <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
            <v-select
                :class="{
                    filtered: currentFilters.type_date,
                    changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
                }"
                :value="currentFilters.type_date"
                @input="
                    (value) => {
                        $emit('inputChange', { inputName: 'type_date', value: value })
                    }
                "
                :items="optionsDate"
                :placeholder="$t('assets.filter.filter_by')"
                :no-data-text="$t('supervise.filter.no_results')"
                hide-details
                class="select"
                item-text="name"
                item-value="value"
                dense
            ></v-select>

            <!-- CUSTOM DATE -->
            <div class="custom" v-if="currentFilters.type_date == 'custom'">
                <!-- Custom date: START DATE -->
                <div class="description">{{ $t('assets.filter.from') }}:</div>
                <input
                    class="date-input start"
                    :value="currentFilters.start_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'start_date', value: event.target.value })
                        }
                    "
                    :max="currentFilters.end_date"
                    type="date"
                />

                <div class="separator"></div>
                <!-- Custom date: END DATE -->
                <div class="description">{{ $t('assets.filter.to') }}:</div>
                <input
                    class="date-input end"
                    :value="currentFilters.end_date"
                    @input="
                        (event) => {
                            $emit('inputChange', { inputName: 'end_date', value: event.target.value })
                        }
                    "
                    :min="currentFilters.start_date"
                    type="date"
                />
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    name: 'SuperviseRecordsFilterInputs',
    components: { Tags },
    props: {
        currentFilters: { type: Object },
        activeFilters: { type: Object }
    },
    data() {
        return {}
    },
    computed: {
        optionsLocations() {
            return this.$store.getters['filterLocations/getFilterLocation']()
        },
        optionsGroups() {
            return this.$store.getters['filterLocations/getFilterGroups']()
        },
        optionsEmployees() {
            return this.$store.getters['records/getFilterEmployees']()
        },
        fullNameEmployee() {
            // Map the optionsEmployees to include full names
            return Object.values(this.optionsEmployees).map((employee) => ({
                ...employee,
                fullName: `${employee.name} ${employee.surname}`
            }))
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                {
                    value: 'groups',
                    name: i18n.t('user.filters.territories')
                }
            ]
        },
    },

    methods: {},

    create() {}
}
</script>

<style lang="scss" scoped></style>
