var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-items"},[_vm._t("default"),_c('div',{staticClass:"filter"},[(_vm.temporaryAvailable == 1)?_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.template_type')))]),(Object.values(_vm.optionsTplType))?_c('v-select',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.tplType ? _vm.currentFilters.tplType && Object.values(_vm.currentFilters.tplType).length > 0 : '',
                    changed:
                        JSON.stringify(_vm.currentFilters.tplType) != JSON.stringify(_vm.activeFilters.tplType) &&
                        _vm.currentFilters.tplType &&
                        Object.values(_vm.currentFilters.tplType).length > 0
                },attrs:{"value":_vm.currentFilters.tplType,"hide-details":"","items":Object.values(_vm.optionsTplType),"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('user.filters.filter_by')},on:{"input":function (value) {
                        _vm.$emit('inputChange', { inputName: 'tplType', value: value })
                    }}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.template')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.template ? _vm.currentFilters.template && Object.values(_vm.currentFilters.template).length > 0 : '',
                    changed:
                        JSON.stringify(_vm.currentFilters.template) != JSON.stringify(_vm.activeFilters.template) &&
                        _vm.currentFilters.template &&
                        Object.values(_vm.currentFilters.template).length > 0
                },attrs:{"value":_vm.currentFilters.template,"items":Object.values(_vm.optionsTemplates),"hide-details":"","item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"filter":_vm.filterAutocomplete,"return-object":""},on:{"input":function (templateInputValue) {
                        _vm.$emit('inputChange', { inputName: 'template', value: templateInputValue })
                    }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}},{key:"item",fn:function(ref){
                    var item = ref.item;
                    var attrs = ref.attrs;
                    var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"options",attrs:{"no-gutters":""},domProps:{"innerHTML":_vm._s(item.name)}})],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}])}),_c('Tags',{attrs:{"tags":_vm.currentFilters.template,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.search_by')))]),(Object.values(_vm.optionsSites))?_c('v-select',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.sites,
                    changed:
                        _vm.currentFilters.sites !== undefined &&
                        _vm.activeFilters.sites !== undefined &&
                        JSON.stringify(_vm.currentFilters.sites) != JSON.stringify(_vm.activeFilters.sites)
                },attrs:{"value":_vm.currentFilters.sites,"hide-details":"","items":Object.values(_vm.optionsSites),"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by')},on:{"input":function (value) {
                        _vm.$emit('inputChange', { inputName: 'sites', value: value })
                    }}}):_vm._e()],1),(_vm.currentFilters.sites == 'locations')?_c('div',{staticClass:"sites"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_sites')))]),(Object.values(_vm.optionsLocations))?_c('v-autocomplete',{staticClass:"select",class:{
                    filtered:
                        _vm.currentFilters.locations && typeof _vm.currentFilters.locations !== 'undefined' && Object.values(_vm.currentFilters.locations).length > 0,
                    changed:
                        JSON.stringify(_vm.currentFilters.locations) != JSON.stringify(_vm.activeFilters.locations) &&
                        _vm.currentFilters.locations &&
                        Object.values(_vm.currentFilters.locations).length > 0
                },attrs:{"value":_vm.currentFilters.locations,"hide-details":"","items":Object.values(_vm.optionsLocations),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.currentFilters.locations ? '' : _vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (locationsInputValue) {
                        _vm.$emit('inputChange', { inputName: 'locations', value: locationsInputValue })
                    }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}],null,false,2422558294)}):_vm._e(),(_vm.currentFilters.locations)?_c('Tags',{attrs:{"tags":_vm.currentFilters.locations,"backImage":'location_inactive_s30.svg',"nameTag":'name',"value":"id","removable":true}}):_vm._e()],1):(_vm.currentFilters.sites == 'groups')?_c('div',{staticClass:"custom"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_territories')))]),(Object.values(_vm.optionsGroups))?_c('v-autocomplete',{staticClass:"select",class:{
                    filtered:
                        _vm.currentFilters.groups &&
                        typeof _vm.currentFilters.groups !== 'undefined' &&
                        _vm.currentFilters.groups &&
                        Object.values(_vm.currentFilters.groups).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.groups) != JSON.stringify(_vm.activeFilters.groups)
                },attrs:{"value":_vm.currentFilters.groups,"hide-details":"","items":Object.values(_vm.optionsGroups),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.currentFilters.groups ? '' : _vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (inputValue) {
                        _vm.$emit('inputChange', { inputName: 'groups', value: inputValue })
                    }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
                    var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}],null,false,2422558294)}):_vm._e(),(_vm.currentFilters.groups)?_c('Tags',{attrs:{"tags":_vm.currentFilters.groups,"nameTag":'name',"value":"id","removable":true}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.timeframe')))]),_c('v-autocomplete',{staticClass:"select",class:{
                filtered: _vm.currentFilters.timeframe && Object.values(_vm.currentFilters.timeframe).length > 0,
                changed:
                    JSON.stringify(_vm.currentFilters.timeframe) != JSON.stringify(_vm.activeFilters.timeframe) &&
                    _vm.currentFilters.timeframe &&
                    Object.values(_vm.currentFilters.timeframe).length > 0
            },attrs:{"value":_vm.currentFilters.timeframe,"hide-details":"","items":Object.values(_vm.optionsTimeframes),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","filter":_vm.filterAutocomplete,"no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'timeframe', value: value })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}])}),_c('Tags',{attrs:{"tags":_vm.currentFilters.timeframe,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.category')))]),_c('v-autocomplete',{staticClass:"select",class:{
                filtered: _vm.currentFilters.category && Object.values(_vm.currentFilters.category).length > 0,
                changed:
                    JSON.stringify(_vm.currentFilters.category) != JSON.stringify(_vm.activeFilters.category) &&
                    _vm.currentFilters.category &&
                    Object.values(_vm.currentFilters.category).length > 0
            },attrs:{"value":_vm.currentFilters.category,"items":_vm.optionsCategories ? Object.values(_vm.optionsCategories) : [],"hide-details":"","item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","filter":_vm.filterAutocomplete,"no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'category', value: value })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}])}),_c('Tags',{attrs:{"tags":_vm.currentFilters.category,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter date"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.date')))]),_c('v-select',{staticClass:"select",class:{
                filtered: _vm.currentFilters.type_date,
                changed: JSON.stringify(_vm.currentFilters.type_date) != JSON.stringify(_vm.activeFilters.type_date)
            },attrs:{"value":_vm.currentFilters.type_date,"items":_vm.optionsDate,"hide-details":"","item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results')},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'type_date', value: value })
                }}}),(_vm.currentFilters.type_date == 'custom')?_c('div',{staticClass:"custom"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.from'))+":")]),_c('input',{staticClass:"date-input start",attrs:{"max":this.currentFilters.end_date,"type":"date"},domProps:{"value":_vm.currentFilters.start_date},on:{"input":function (event) {
                        _vm.$emit('inputChange', { inputName: 'start_date', value: event.target.value })
                    }}}),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.to'))+":")]),_c('input',{staticClass:"date-input end",attrs:{"min":_vm.currentFilters.start_date,"type":"date"},domProps:{"value":_vm.currentFilters.end_date},on:{"input":function (event) {
                        _vm.$emit('inputChange', { inputName: 'end_date', value: event.target.value })
                    }}})]):_vm._e()],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.completed_tasks')))]),_c('v-range-slider',{staticClass:"score",attrs:{"step":"5","value":_vm.currentFilters.score,"hide-details":"","thumb-size":"25","thumb-label":"always"},on:{"input":function (value) {
                    _vm.$emit('inputChange', { inputName: 'score', value: value })
                }}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('template.general.tags.title')))]),_c('v-autocomplete',{staticClass:"select",class:{
                filtered: _vm.currentFilters.tags && typeof _vm.currentFilters.tags !== 'undefined' && Object.values(_vm.currentFilters.tags).length > 0,
                changed: JSON.stringify(_vm.currentFilters.tags) != JSON.stringify(_vm.activeFilters.tags)
            },attrs:{"hide-details":"","items":Object.values(_vm.optionsTags),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}]),model:{value:(_vm.currentFilters.tags),callback:function ($$v) {_vm.$set(_vm.currentFilters, "tags", $$v)},expression:"currentFilters.tags"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.tags,"nameTag":'name',"value":"id","removable":true}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }